.single{
    display: flex;
    width: 100%;
    .singleContainer{
        flex: 6;

        .topSingle{
            padding: 20px;
            display: flex;
            gap: 20px;

            .leftSingle{
                flex: 1;
                -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                padding: 20px;
                position: relative;


                .itemSingle {
                    display: flex;
                    gap: 20px;

                    .detailsSingle {
                        .nameTitleSingle {
                          margin-bottom: 10px;
                          color: rgb(241, 19, 19);
                        }
            
                        .count {
                          margin-bottom: 10px;
                          font-size: 14px;
            
                          .countKey{
                            font-weight: bold;
                            color: gray;
                            margin-right: 5px;
                          }
            
                          .countValue{
                            font-weight: 300;
                          }
                        }


                      }
                    }
                }

            .rightSingle{
                flex: 2;
            }
        }
    }
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

.container{
    

    .div-container{
        flex: 6;
    }

        .widgets, .chartsClassOverall, .posNegCharts{
            display: flex;
            padding: 20px;
            gap: 20px;
    }

    .chartsClassOverall{
        padding: 5px 20px;
    }

    .posNegCharts{
        padding: 5px 20px;
    }

    
}