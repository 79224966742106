@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}


body{
    position: relative;
    min-height: 100vh;
    width: 100%;
    
}

.col > div {
    background: lightblue;
    height: 120px;
  }
  #inlineCheckbox1 {
    all: revert;
    text-align : left;
    
    
     }



.container{
    

    .div-container{
        flex: 6;
    }

        .widgets, .chartsClassOverall, .posNegCharts{
            display: flex;
            padding: 20px;
            gap: 20px;

            
    }

    .chartsClassOverall{
        padding: 5px 20px;
    }

    .settingsTitle{
        font-weight: bold;
        font-size: 14px;
        color: rgb(160, 160, 160);
    }

    .posNegCharts{
        padding: 5px 20px;
    }

    .top{
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title{
            font-size: 16px;
            font-weight: 500;
        }
    }

    .bottom{
        
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;

        .featuredChart {
            width: 100px;
            height: 100px;
        }
    }

    
}

