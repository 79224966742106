
.sidebar {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 90px;
	background: #11101d;
	padding: 6px 14px;
	transition: all 0.5s ease;
	.logo_content {
		.logo {
			color: #fff;
			display: flex;
			height: 50px;
			width: 100%;
			align-items: center;
			opacity: 0;
			pointer-events: none;
		}
	}
	#sidenav_btn {
		position: absolute;
		color: #fff;
		left: 50%;
		top: 6px;
		font-size: 20px;
		height: 50px;
		width: 50px;
		text-align: center;
		line-height: 50px;
		transform: translateX(-50%);
	}
	ul {
		margin-top: 20px;
		li {
			position: relative;
			height: 50px;
			width: 100%;
			margin: 0 5px;
			list-style: none;
			line-height: 50px;
			.tooltip {
				position: absolute;
				left: 172px;
				top: 0;
				transform: translate(-75%, -50%);
				border-radius: 6px;
				height: 35px;
				width: 122px;
				background: #fff;
				line-height: 35px;
				text-align: center;
				box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
				transition: 0s;
				opacity: 0;
				pointer-events: none;
				display: block;
			}
			&:hover {
				.tooltip {
					transition: all 0.5s ease;
					opacity: 1;
					top: 50%;
				}
				.tooltip-long {
					transition: all 0.5s ease;
					opacity: 1;
					top: 50%;
				}
			}
			.tooltip-long {
				position: absolute;
				left: 222px;
				top: 0;
				transform: translate(-70%, -50%);
				border-radius: 6px;
				height: 35px;
				width: 200px;
				background: #fff;
				line-height: 35px;
				text-align: center;
				box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
				transition: 0s;
				opacity: 0;
				pointer-events: none;
				display: block;
			}
			a {
				color: #fff;
				display: flex;
				align-items: center;
				text-decoration: none;
				transition: all 0.4s ease;
				border-radius: 12px;
				white-space: nowrap;
				&:hover {
					color: #11101d;
					background: #fff;
				}
			}
			box-icon {
				
				min-width: 50px;
				border-radius: 12px;
				line-height: 50px;
				text-align: center;
			}
			.sidenav_search {
				width: 5%;
				opacity: 0;
			}
			.menu {
				position: absolute;
				z-index: 99;
				color: #fff;
				transition: all 0.5 ease;
				&:hover {
					background: #fff;
					color: #1d1b31;
				}
			}
		}
	}
	.link_name {
		opacity: 0;
		pointer-events: none;
	}
	.profile_content {
		position: absolute;
		color: #fff;
		bottom: 0;
		left: 0;
		width: 100%;
		.profile {
			position: relative;
			padding: 10px 6px;
			height: 60px;
			background: #1d1b31;
		}
	}
}
.sidebar.active {
	width: 270px;
	.logo_content {
		.logo {
			opacity: 1;
			pointer-events: none;
		}
	}
	#sidenav_btn {
		left: 90%;
	}
	ul {
		li {
			.tooltip {
				display: none;
			}
			.tooltip-long {
				display: none;
			}
			.sidenav_search {
				position: absolute;
				height: 100;
				width: 70%;
				left: 0;
				top: 0;
				border-radius: 12px;
				outline: none;
				border: none;
				background: #1d1b31;
				padding-left: 50px;
				font-size: 18px;
				color: #fff;
				opacity: 1;
			}
		}
	}
	.link_name {
		opacity: 1;
		pointer-events: auto;
	}
	.profile {
		#logout_btn {
			left: 88%;
		}
	}
}
.logo_content {
	.logo {
		i {
			font-size: 28px;
			margin-right: 5px;
		}
		.logo_name {
			font-size: 20px;
			font-weight: 400;
		}
	}
}
.profile_content {
	.profile {
		.profile_details {
			display: flex;
			align-items: center;
			opacity: 0;
			pointer-events: none;
			white-space: nowrap;
		}
	}
}
.sidebar.sidebar.active {
	.profile {
		.profile_details {
			opacity: 1;
			pointer-events: auto;
		}
	}
}
.profile {
	.profile_details {
		.nameandbusiness {
			margin-left: 10px;
		}
		.profile_name {
			font-size: 15px;
			font-weight: 400;
		}
		.profile_business {
			font-size: 12px;
		}
	}
	#logout_btn {
		position: absolute;
		left: 50%;
		bottom: 5px;
		transform: translateX(-50%);
		min-width: 50px;
		
		font-size: 20px;
		border-radius: 12px;
		text-align: center;
	}
}

.form {
	background: #fff;
	width: 600px;
	height: 55px;
	display: flex;
	input {
		flex: 1;
		border: none;
		outline: none;
	}
	button {
		
		padding: 10px 50px;
		border: none;
		outline: none;
		color: #fff;
		letter-spacing: 1px;
		cursor: pointer;
	}
	.search-alt {
		align-self: center;
		padding: 10px 20px;
		color: #777;
	}
}
.loader {
	display: none;
	top: 50%;
	left: 50%;
	position: absolute;
	transform: translate(-50%, -50%);
}
.loading {
	border: 2px solid #ccc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	border-top-color: #1ecd97;
	border-left-color: #1ecd97;
	animation: spin 1s infinite ease-in;
}
.textLoading {
	position: absolute;
	top: 75%;
	left: 50%;
	font-size: 50px;
	color: rgb(0, 67, 250);
	transform: translate(-50%,-50%);
	-ms-transform: translate(-50%,-50%);
}
.overlayLoading {
	position: fixed;
	display: none;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.5);
	z-index: 9999;
	cursor: pointer;
}

.button {
	background: #ee6e73;
	
}

.myLoading {
	height: 100vh;
  }

@keyframes "spin" {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}