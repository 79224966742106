.brand-logo {
    display: inline-flex !important;
    width: 280px;
    min-height: 5px;
    height: 60px;
    
    background-size: 255px 40px;
    text-indent: -9999px;
    display: block;
    
  }